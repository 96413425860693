import "./index.css";
import Rate from "../../shared/StarRating";
import { FaCalendar } from "react-icons/fa";

const index = () => {
  return (
    <div className="trip-history">
      <div className="history-container">
        <h1>Seyahat Geçmişim</h1>

        <div className="history-detail-headers">
          <div className="headers-inner">
            <h1 className="day-header">Gün</h1>

            <h1 className="place-header">Yer</h1>

            <h1 className="rate-header">Puan</h1>
          </div>
        </div>

        <div className="history-detail-content">
          <div className="calendar">
            <div className="calendar-icon">
              <FaCalendar />
            </div>

            <div className="month">OCA</div>
          </div>
          <div className="detail-info-container">
            <div className="detail-info-inner">
              <p className="date-entered">11</p>
              <p className="place-entered">Galata Kulesi</p>
              <p className="rate-entered">
                <Rate rating={4} />
              </p>
            </div>
          </div>
        </div>
        <div className="history-detail-content">
          <div className="calendar">
            <div className="calendar-icon">
              <FaCalendar />
            </div>

            <div className="month">OCA</div>
          </div>
          <div className="detail-info-container">
            <div className="detail-info-inner">
              <p className="date-entered">11</p>
              <p className="place-entered">Galata Kulesi</p>
              <p className="rate-entered">
                <Rate rating={4} />
              </p>
            </div>
          </div>
        </div>
        <div className="history-detail-content">
          <div className="calendar">
            <div className="calendar-icon">
              <FaCalendar />
            </div>

            <div className="month">OCA</div>
          </div>
          <div className="detail-info-container">
            <div className="detail-info-inner">
              <p className="date-entered">11</p>
              <p className="place-entered">Galata Kulesi</p>
              <p className="rate-entered">
                <Rate rating={4} />
              </p>
            </div>
          </div>
        </div>
        <div className="history-detail-content">
          <div className="calendar">
            <div className="calendar-icon">
              <FaCalendar />
            </div>

            <div className="month">OCA</div>
          </div>
          <div className="detail-info-container">
            <div className="detail-info-inner">
              <p className="date-entered">11</p>
              <p className="place-entered">Galata Kulesi</p>
              <p className="rate-entered">
                <Rate rating={4} />
              </p>
            </div>
          </div>
        </div>
        <div className="history-detail-content">
          <div className="calendar">
            <div className="calendar-icon">
              <FaCalendar />
            </div>

            <div className="month">OCA</div>
          </div>
          <div className="detail-info-container">
            <div className="detail-info-inner">
              <p className="date-entered">11</p>
              <p className="place-entered">Galata Kulesi</p>
              <p className="rate-entered">
                <Rate rating={4} />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default index;
