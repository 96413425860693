import Modal from "react-modal";
import { useState } from "react";

import { Button, CloseButton } from "../../shared/Button";
import Rate from "../../shared/StarRating";

import styles from "./index.module.css";
import toast from "react-hot-toast";

import { CommentServices } from "../../services";

const customStyles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 99999999,
    backgroundColor: "rgba(0,0,0, .60)",
    backdropFilter: "blur(7px)",
    transition: "300ms all",
  },
  content: {
    position: "absolute",
    display: "flex",
    width: "590px",
    height: "400px",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    border: "none",
    background: "transparent",
    overflowY: "auto",
    overflowX: "hidden",
    borderRadius: "5px",
    outline: "none",
    padding: 0,
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.38)",
    zIndex: 999999999,
  },
};

function CommentPopUp({place_id, place_name, openModal,setModal}) {
  const [rate, setRate] = useState(0);
  const [content, setContent] = useState("");

  const data = {
    place_id,
    rate,
    content:content.toString(),
  };

  const sendComment = () => {
    CommentServices.addNewComment(data)
    .then(message=>{
        setModal(false);
        toast.success("Yorum başarıyla gönderildi.");
    })
    .catch(err => {
      console.log(err);
      toast.error("Yorum gönderilemedi.");
    } ) 
    ;
  
  };

  return (
    <Modal
      isOpen={openModal}
      ariaHideApp={false}
      onRequestClose={() => setModal(false)}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <div className={styles.container}>

        <div className={styles.detailContent}>
          <div className={styles.commentTitle}>
            <h1><b>{place_name}</b> hakkında aşağıdan puanlayıp yorum yazabilirsin!</h1>
          </div>

          <div className={styles.rate}>
            <Rate rating={rate} starDimension={45} changeRate={setRate} />
          </div>

          <div className={styles.commentBox}>
            <textarea
              placeholder="Yorumunuzu yazınız.."
                spellCheck="false" 
              value={content}
              onChange={(e) => {
                setContent(e.target.value);
              }}
            ></textarea>
          </div>
          <div className={styles.buttonContainer}>
            <Button
              text="Kaydet"
              theme="primary "
              onClick={sendComment}
              className={styles.button}
            />
          </div>
        </div>
      </div>
      <CloseButton onClick={() => setModal(false)} />
    </Modal>
  );
}

export default CommentPopUp;
