import PlaceCardPhoto from "../../assets/PlaceCardPhoto.svg";

import styles from "./index.module.css";
import { FaHeart } from "react-icons/fa";
import { Link } from "react-router-dom";
const index = ({id, image, placeName, cityName,desc}) => {
  return (
    <div className={styles.placeCardPopUp}>
      <div className={styles.placeCardPopUpContainer}>
        <div className={styles.placePhoto}>
          <img
            src={image}
            alt={placeName}
          />
        </div>
        <div className={styles.placeName}>{placeName}</div>
        <div className={styles.placeDescription}>{desc}</div>
        <div className={styles.buttons}>
          <Link to={`/place/${id}`}>
         
            <button className={styles.seeMore}>Detay sayfasına git</button>
          </Link>

          <button className={styles.addToFavs}>
            <FaHeart />
          </button>
        </div>
      </div>
    </div>
  );
};

export default index;
