import { useParams } from "react-router-dom";
import { useState ,useEffect } from "react";

import styles from "./index.module.css";

import { FaRegEdit } from "react-icons/fa";

import ProfileList from "../../shared/ProfileList";
import ReviewContent from "../../components/ReviewContent";
import TripHistoryWidget from "../../components/TripHistoryWidget";
import ProfileSkeleton from "./ProfileSkeleton";

import { Toaster } from "react-hot-toast";

import { users, defaultUser } from "../../assets/dummy/users.js";
import { UserServices } from "../../services";
import { useAuth } from "../../context/AuthContext";

import { useQuery } from "@tanstack/react-query";


const Profile = () => {
  const { user:sessionUser } = useAuth();
  let { username } = useParams();
  let user;

  console.log(username);

  // Eğer /profile girilmişse örnek amaçlı dönen veri
  if (typeof username === "undefined") {
  }
  user = defaultUser;

  const { isLoading, isError, data, error } = useQuery(
    ["profile", username],
    async () => {
      return await UserServices.getUserWithUserName(username)
        .then((data) => data)
        .catch((err) => err);
    }
  );

  const changeProfile = (e) => {
    UserServices.changeUserProfile({"file": e.target.files[0] })
    .then(data=>console.log(data))
    .then(err=>console.log(err))
  }

  const changeBanner = (e) => {
    UserServices.changeUserBanner({"file": e.target.files[0] })
    .then(data=>console.log(data))
    .then(err=>console.log(err))
  }

  if (isLoading) {
    return <ProfileSkeleton />;
  }

  const isSessionUser = data[0]?.id === sessionUser.id; //Oturumu açık olan hesaba giriş yapılmış ise görseller düzenlenbilir hale gelecektir.

  return (
    <>
    <Toaster position="bottom-center"/>
      <div
        className={
          styles.profileContainer + " " + (isSessionUser ? styles.editable : "")
        }
      >
        <div className={styles.profileContent}>
          <div className={styles.bannerSection}>
            <div className={styles.bannerImageContent}>
              <img
                src={data[0]?.cover_photo === null ?`https://avatars.dicebear.com/api/initials/${data[0].user_name}.svg` : process.env.REACT_APP_API_URL + "/" + data[0].cover_photo}
                alt="Kapak Görseli"
                className={styles.bannerImage}
                loading="lazy"
              />
              { isSessionUser && (
              <label htmlFor="fileInput2">
                <FaRegEdit className={styles.bannerEdit} />
                <input type="file" id="fileInput2"  onChange={changeBanner}  accept="image/*" />
              </label>
              )}
            </div>
            <div className={styles.userInfoSection}>
              <div className={styles.profileImageContent}>
                <img
                  src={data[0]?.profile_photo === null ?`https://avatars.dicebear.com/api/initials/${data[0].user_name}.svg` : process.env.REACT_APP_API_URL + "/" + data[0].profile_photo}
                  alt={user.name}
                  loading="lazy"
                />
                { isSessionUser && (
                <label htmlFor="fileInput">
                  <FaRegEdit className={styles.profileEdit} />
                  <input type="file" id="fileInput" onChange={changeProfile} accept="image/*"  />
                </label>
                )}
              </div>
              <div className={styles.userInfo}>
                <div className={styles.userTextInfo}>
                  <h4>{data[0].name + " " + data[0].surname}</h4>
                  <h6>@{data[0].user_name}</h6>
                </div>
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/da/Flag_of_Turkey_%28alternate%29.svg/320px-Flag_of_Turkey_%28alternate%29.svg.png"
                  alt="Flag"
                  className={styles.flag}
                />
              </div>
            </div>
          </div>
          <div className={styles.profileDetailContainer}>
            <div className={styles.leftSide}>
              <ProfileList
                path={`/visited/${data[0]?.user_name}`}
                title="Gidilenler"
                items={data[0]?.get_visited_places.slice(0).reverse().slice(0,3)}
              />
              <ProfileList title="Favoriler" 
                path={`/fav/${data[0]?.user_name}`}
               items={data[0]?.get_fav_places.slice(0).reverse().slice(0,3)}
               />
              <ProfileList title="Gitmek İstenen" 
                path={`/go/${data[0]?.user_name}`}
               items={data[0]?.get_go_places.slice(0).reverse().slice(0,3)}
              />
            </div>
            <div className={styles.rightSide}>
              {data[0].get_comments &&
                data[0].get_comments.slice(0).reverse().map((review) => {
                  return (
                    <ReviewContent
                      comments={data[0].get_comments}
                      userId={review?.user_id}
                      userImage={data[0]?.profile_photo === null ?`https://avatars.dicebear.com/api/initials/${data[0].user_name}.svg` : process.env.REACT_APP_API_URL + "/" + data[0].profile_photo}
                      key={review?.id}
                      commentId={review?.id}
                      placeId={review?.places?.id}
                      placeName={review?.places?.place_name}
                      rate={review?.rate}
                      content={review?.content}
                      timeAgo={review?.created_at}
                      borderShow={false}
                      user={data[0]} 
                  
                    />
                  );
                })}
              <TripHistoryWidget />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
