import { createContext, useContext, useState, useEffect } from "react";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("_user")) || []);
  const [token, setToken] = useState(localStorage.getItem("_token") || "");

  useEffect(() => {
    localStorage.setItem("_user", JSON.stringify(user));
  }, [user]);

  useEffect(() => {
    localStorage.setItem("_token", token);
  }, [token]);

  const logout = ()=>{
    setUser([]);
    setToken("");
    localStorage.removeItem("_token");
    localStorage.removeItem("_user");
  }

  const values = {
    token,
    setToken,
    user,
    setUser,
    logout
  };

  return (
    <AuthContext.Provider value={values}>{children}</AuthContext.Provider>
  );
};

const useAuth = () => useContext(AuthContext);

export { useAuth, AuthProvider };
