import PlaceCardIcon from "../../assets/PlaceCardIcon.svg";
import PlaceCardPhoto from "../../assets/PlaceCardPhoto.svg";
import PlaceCardPopUp from "../PlaceCardPopUp";
import { CloseButton } from "../../shared/Button";
import { FaSwimmer } from "react-icons/fa";
import Modal from "react-modal";
import { useState } from "react";
import "./index.css";

const customStyles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 999999999,
    backgroundColor: "rgba(0,0,0, .69)",
  },
  content: {
    position: "absolute",
    width: "519px",
    height: "471px",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    border: "none",
    backgroundColor: "rgba(0,0,0, .69)",
    overflowY: "auto",
    overflowX: "hidden",
    borderRadius: "5px",
    outline: "none",
    padding: 0,
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.38)",
    zIndex: 999999999,
  },
};

const PlaceCard = ({ id, image, placeName, cityName, desc }) => {
  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }
  return (
    <div className="place-card-wrapper">
      <div className="place-card-content" onClick={openModal}>
        <div className="place-card-container">
          <a className="place-card-item">
            <img
              src={image}
              alt={placeName}
              className="place-card-photo"
              onClick={openModal}
            />
            <div className="place-card-icon">
              <p>
                <FaSwimmer />
              </p>
            </div>
            <div className="place-card-info-headers">
              <h3>{placeName}</h3>
              <h4>{cityName}</h4>
            </div>
            <div className="place-card-description">
              <p>{desc}</p>
            </div>
          </a>
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        ariaHideApp={false}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <PlaceCardPopUp
          id={id}
          image={image}
          placeName={placeName}
          cityName={cityName}
          desc={desc}
        />
        <CloseButton onClick={closeModal} />
      </Modal>
    </div>
  );
};

export default PlaceCard;
