import { get, post, put, deleteRequest } from "./request";

// Get Requests
export const getAllComments = () => get("/all_comment");

export const getSingleCommentById = (comment_id) =>
  get(`/comment/${comment_id}`);

export const getCommentsByUserId = (user_id, quantity = 10, counter = 0) =>
  get(`/comments/${user_id}?quantity=${quantity}&counter=${counter}`);

export const getCommentsByUserName = (user_name, quantity = 10, counter = 0) =>
  get(`/comments/${user_name}?quantity=${quantity}&counter=${counter}`);

export const getHighestCommentsByUserId = (
  user_id,
  quantity = 10,
  counter = 0
) =>
  get(
    `/comments/${user_id}?quantity=${quantity}&counter=${counter}&rate=highest`
  );

export const getLowestCommentsByUserId = (
  user_id,
  quantity = 10,
  counter = 0
) =>
  get(
    `/comments/${user_id}?quantity=${quantity}&counter=${counter}&rate=lowest`
  );

export const getOldestCommentsByUserId = (
  user_id,
  quantity = 10,
  counter = 0
) =>
  get(
    `/comments/${user_id}?quantity=${quantity}&counter=${counter}&date=oldest`
  );

// Post Requests
export const addNewComment = (data) => post("/comment", data);

//Put Request
export const updateCommentById = (comment_id, data) =>
  put(`/comment/${comment_id}`, data);

//Delete Request
export const deleteCommentById = (comment_id) =>
  deleteRequest(`/comment/${comment_id}`);
