import { useState , useEffect} from "react";

export const TranportationIcon = ({ icon, type,transportation, setTransportation }) => {
  const [transportationClick, setTransportationClick] = useState(false);
  const handleTransportationClick = () => {
    setTransportationClick(!transportationClick);
    if(!transportation.includes(type))
      setTransportation([type,...transportation]);
    else
      setTransportation(transportation.filter((element)=>element!==type))
  };


  useEffect(()=>{
    if(transportation.includes(type))
      setTransportationClick(true);
    else
      setTransportationClick(false)
  },[transportation])

  return (
    <div className="transportation-icon-container">
      <div
        className={
          transportationClick ? "transportation active" : "transportation"
        }
        onClick={handleTransportationClick}
      >
        <div className="transportation-icon">{icon}</div>
      </div>
      <h1 style={{ color: "white" }}>{type}</h1>
    </div>
  );
};

export default TranportationIcon;
