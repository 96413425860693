
import styles from "./styles.module.css"
import { useState, useEffect,useMemo } from "react";
import { Link, useLocation } from "react-router-dom";

import { useAuth } from "../../context/AuthContext";
import { AdvisorServices } from "../../services";
import categories from "../Pusula/CategoryIcon/categories";
import transportations from "../Pusula/TranportationIcon/tranportations"

import SuggestionPlace from "./SuggestionPlace";
import Loading from "../Loading";


function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}


const Suggestions = () => {
  const { user } = useAuth();
  let query = useQuery();

  const [loading, setLoading] = useState(true);
  const [done, setDone] = useState(undefined);
  const [data,setData] = useState([])
  
  const city = query.get("city") ;
  const categoriesParam = query.getAll("category") ;
  const tranportationParam = query.getAll("tranportation") ;

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
      setDone(true);
    }, 4000);
  }, []);

  useEffect(()=>{
    const bodyData ={
      "category":categoriesParam[0] || "eğlence",
      "transportation":tranportationParam[0] || "yaya",
      "city":city || "istanbul",
    }
  
      AdvisorServices.getAdvise(bodyData)
      .then(data=>{
        console.log(data)
        setData(data)
      })
      .catch(err=>{
        console.error(err)
      })
  },[])

  return (
    <div className={styles.suggestionContainer}>
      
      {!done && loading ? (
          <Loading />
        ) : (
      <div className={styles.content}>
        <div className={styles.header}>
          <img src={data?.city?.photo} alt="City" loading="lazy" draggable="false"/>

          <div className={styles.headerInner}>
            <h1>{data?.city?.city_name}</h1>
            <h5>PUSULA ÖNERİ LİSTESİ</h5>
            <h3><Link to={`/profile/${user?.user_name}`} >{user?.user_name}</Link> için hazırlandı.</h3>
          </div>
        </div>

        <div className={styles.itemSection}>
          <div className={styles.titleDivider}>
            <h2 className={styles.title}>SENİN İÇİN EN UYGUN SEÇİMLER</h2>
          </div>
          <div className={styles.places}>
            {data?.places?.map((place)=>{
              return <SuggestionPlace key={place?.id} id={place?.id} categoryId={place?.category_id} place={place?.place_name} desc={place?.description} image={place?.photo_link}/>
            })}
          </div>
        </div>

        <div className={styles.selectedSection}>
          <div className={styles.left}>
            <h2 className={styles.title}>SEÇİLİ KATEGORİLER</h2>
            <div className={styles.icons}>
              {categoriesParam?.map((category,index)=>{
                const cat = categories.find((el)=>el?.type===category.trim());
                return <div className={styles.iconSide} key={index}>{cat?.icon}</div>
              })}
            </div>
          </div>

          <div className={styles.right}>
            <h2 className={styles.title}>SEÇİLİ ULAŞIM ŞEKİLLERİ</h2>
          <div className={styles.icons}>
              {tranportationParam?.map((trans,index)=>{
                const tr = transportations.find((el)=>el?.type===trans.trim());
                return <div className={styles.iconSide} key={index}>{tr?.icon}</div>
              })}
            </div>
          </div>
        </div>
      </div>)}
    </div>

  );
};

export default Suggestions;
