import Hero from "./components/Hero";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import PlaceDetail from "./pages/PlaceDetail";
import Profil from "./pages/Profil";
import ProfileSkeleton from "./pages/Profil/ProfileSkeleton";
import TripHistory from "./pages/TripHistory";
import ReviewDetail from "./components/ReviewDetail";
import Register from "./pages/Register";
import Pusula from "./pages/Pusula";
import Suggestions from "./pages/Suggestions";
import DateSelector from "./components/DateSelector";
import MyReviews from "./pages/MyReviews";
import Loading from "./pages/Loading";
import Layout from "./pages/Layout";
import PlaceStatic from "./pages/PlaceDetail/PlaceStatic";
import Login from "./pages/Login";
import VisitedPlaces from "./pages/VisitedPlaces";

function App() {
  return (
    <div>
      
      <Routes>
        <Route path="/" element={<Layout />} >
          <Route index element={<Home />} />
          <Route path="footer" element={<Footer />} />
          <Route path="hero" element={<Hero />} />
          <Route path="home" element={<Home />} />
          <Route path="place-detail" element={<PlaceStatic />} />
          <Route path="profile" element={<Profil />} />
          <Route path="profile/:username" element={<Profil />} />
          <Route path="place/:place_id" element={<PlaceDetail />} />
          <Route path="profile-skeleton" element={<ProfileSkeleton />} />
          <Route path="trip-history" element={<TripHistory />} />
          <Route path="review-detail" element={<ReviewDetail />} />
          <Route path="pusula" element={<Pusula />} />
          <Route path="suggestions" element={<Suggestions />} />
          <Route path="date-selector" element={<DateSelector />} />
          <Route path="my-reviews" element={<MyReviews/>}/>
          <Route path="review-detail" element={<ReviewDetail />} />
          <Route path="loading-page" element={<Loading />} />
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route path=":type/:username" element={<VisitedPlaces/>} />
        </Route>
      </Routes>

      {/* <Hero /> */}
      {/* <Footer /> */}
      {/* <ReviewDetail/> */}
      {/* <LocationSelector />  */}
      {/* <SurveyModal/>  */}
      {/* <DataEvaulation/> */}
    </div>
  );
}

export default App;
