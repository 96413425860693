import { FaClock } from "react-icons/fa";
import toast, { Toaster } from "react-hot-toast";

import { useState } from "react";
import { Link } from "react-router-dom";

import "./index.css";
import PusulaPage from "../../assets/PusulaPage.png";
import DateSelector from "../../components/DateSelector";
import TranportationIcon from "./TranportationIcon";
import CategoryIcon from "./CategoryIcon";
import LocationSelector from "../../components/LocationSelector";

import categories from "./CategoryIcon/categories";
import transportations from "./TranportationIcon/tranportations";

const Pusula = () => {
  const [selectorModal, setSelectorModal] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedTransportation, setSelectedTransportation] = useState([]);

  const [selectedCity, setSelectedCity] = useState({
    code: 48,
    city_name: "Muğla",
    photo: "https://cdn.goturkiye.com/goturkey/5-mugla.jpg",
  });

  const ResetNotify = () => {
    setSelectedCategory([]);
    setSelectedTransportation([]);
    toast.error("Tercihler sıfırlandı.")
  };
  
  const SavingNotify = () => toast.success("Tercihler kaydedildi.");

  return (
    <>
      <LocationSelector
        modal={selectorModal}
        setModal={setSelectorModal}
        selectedCity={selectedCity}
        setSelectedCity={setSelectedCity}
      />
      <div className="trip-advisor">
        <div className="trip-advisor-inner">
          <div className="trip-advisor-container">
            <div className="trip-advisor-upper">
              <img src={PusulaPage} alt="PusulaPage" />
              <div className="trip-advisor-header">
                <h1>Pusula</h1>
                <p>
                  {selectedCity.city_name} için bize biraz daha planlarından
                  bahset.
                </p>
              </div>
            </div>

            <div className="date-info">
              <div className="start-date">
                <div className="start-header">
                  <p>Başlangıç Tarihi</p>
                </div>

                <div className="date-selector">
                  <div className="clock-icon">
                    <FaClock />
                  </div>
                  <p>
                    <DateSelector />
                  </p>
                </div>
              </div>

              <div className="end-date">
                <div className="end-header">
                  <p>Bitiş Tarihi</p>
                </div>

                <div className="date-selector">
                  <div className="clock-icon">
                    <FaClock />
                  </div>
                  <p>
                    <DateSelector />
                  </p>
                </div>
              </div>
            </div>

            <div className="category-selector">
              <div className="category-selector-header">
                <p>Kategori seç.</p>
              </div>
              <div className="categories">
                {categories &&
                  categories.map((category) => {
                    return (
                      <CategoryIcon
                        key={category.id}
                        icon={category.icon}
                        type={category.type}
                        category={selectedCategory}
                        setCategory={setSelectedCategory}
                      />
                    );
                  })}
              </div>
            </div>

            <div className="transportation-selector">
              <div className="transportation-selector-header">
                <p>Ulaşım şekli seç.</p>
              </div>
              <div className="transportation-types">
                {transportations &&
                  transportations.map((tranportation) => {
                    return (
                      <TranportationIcon
                        key={tranportation.id}
                        icon={tranportation.icon}
                        type={tranportation.type}
                        transportation={selectedTransportation}
                        setTransportation={setSelectedTransportation}
                      />
                    );
                  })}
              </div>
            </div>

            <div className="trip-advisor-changes">
              <button className="reset-btn" onClick={ResetNotify}>
                Tercihleri sıfırla
              </button>

              <Link
                to={`/suggestions?city=${
                  selectedCity.city_name
                }${selectedCategory
                  .map((category) => `&category=${category}`)
                  .join(" ")
                }${selectedTransportation
                  .map((category) => `&tranportation=${category}`)
                  .join(" ")}`}
              >
                <button className="save-btn" onClick={SavingNotify}>
                  Devam Et
                </button>
              </Link>

              <Toaster position="bottom-center" reverseOrder={false} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Pusula;
