import { useState } from "react";
import styles from "./index.module.css";
import { AiOutlineClose, AiOutlineSearch } from "react-icons/ai";
import { motion } from "framer-motion";

function SearchContent({ data,setModal, selectedCity, setSelectedCity}) {
  const [filteredData, setFilteredData] = useState(data);
  const [wordEntered, setWordEntered] = useState("");

  const handleFilter = (event) => {
    const searchWord = event.target.value;
    setWordEntered(searchWord);
    const newFilter = data.filter((value) => {
      return value.city_name
        .toLowerCase()
        .includes(searchWord.trim().toLowerCase());
    });

    if (searchWord === "") {
      setFilteredData(data);
    } else {
      setFilteredData(newFilter);
    }
  };

  const clearInput = () => {
    setFilteredData(data);
    setWordEntered("");
  };

  const variants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      variants={variants}
      className={styles.search}
    >
      <div className={styles.searchInputs}>
        <input
          type="text"
          placeholder="Şehir ara"
          value={wordEntered}
          onChange={handleFilter}
        />

        <div className={styles.icon}>
          {wordEntered === "" ? (
            <AiOutlineSearch className={styles.searchIcon} />
          ) : (
            <AiOutlineClose
              id="clearBtn"
              onClick={clearInput}
              className={styles.searchIcon}
              size="16"
            />
          )}
        </div>
      </div>

      <div className={styles.cities}>
        {filteredData.length !== 0 ? (
          filteredData.slice(0, 15).map((value, key) => {
            return (
              <div
                key={key}
                className={styles.city}
                onMouseMove={() => setSelectedCity(value)}
                title={value.link + "/" + value?.city_name.toLowerCase()}
                onClick={() => setModal(false)}
              >
                {value?.city_name}
              </div>
            );
          })
        ) : (
          <div className={styles.empty}>
            "{wordEntered}" için hiçbir sonuç bulunamadı!
          </div>
        )}
      </div>
    </motion.div>
  );
}

export default SearchContent;
