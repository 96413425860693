import { useFormik } from "formik";
//import loginSchema from "../../validations";
import { AuthServices } from "../../services";
import { useNavigate , Link} from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { useAuth } from "../../context/AuthContext";

import styles from "./index.module.css";

import Input from "../../shared/Input";
import { SignIn, Button } from "../../shared/Button";

import LeftBottom from "../../assets/left-bottom.svg";
import RightTop from "../../assets/right-top.svg";

function LoginContainer({setLoginModal}) {

  const {setUser, setToken } = useAuth();
  const navigate = useNavigate();

  const { handleSubmit, handleChange, handleBlur, values, errors, touched } =
    useFormik({
      initialValues: {
        email: "",
        password: "",
      },
      onSubmit: (values) => {
        const data = {
          user_name_email: values.email,
          password :values.password,
        }
        AuthServices.login(data)
        .then(message=>{
          console.log(message)
          toast.success("Başarıyla giriş yapıldı.");
          setUser(message?.user);
          setToken(message?.token);
          setLoginModal(false);
          navigate(-1);
        })
        .catch(err=>{
          console.error(err);
          toast.error(err?.message);

        })
        console.log(values);
      },
    });

  return (
    <div className={styles.LoginContainer}>
      <img
        src={LeftBottom}
        alt="Left Bottom"
        className={styles.LeftBottom}
        draggable={false}
      />
      <img
        src={RightTop}
        alt="Right Top"
        className={styles.RightTop}
        draggable={false}
      />
      <div className={styles.content}>
        <div className={styles.header}>
          <h2>Hoş geldin!</h2>
          <h4>
            Bizimle beraber Türkiye’nin güzelliklerini keşfetmek için giriş yap.
          </h4>
        </div>
        <div className={styles.form}>
          <form onSubmit={handleSubmit}>
            <Input
              isError={touched.email && errors.email ? true : false}
              errorText={errors.email}
              labelText="E-mail veya kullanıcı adı"
              placeholder="Mail adresinizi veya kullanıcı adı giriniz"
              name="email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              style={{ marginBottom: "14px" }}
            />
            <Input
              isError={touched.password && errors.password ? true : false}
              errorText={errors.password}
              labelText="Şifre"
              type="password"
              name="password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              autoComplete="on"
              placeholder="Şifrenizi giriniz"
              style={{ marginBottom: "14px" }}
            />
          
            <Button
              theme="success"
              text="Giriş Yap"
              type="submit"
              style={{ marginBottom: "19px" }}
            />
            {/* <div className={styles.or} style={{ marginBottom: "19px" }}>
              <span></span>
              <h5>VEYA</h5>
              <span></span>
            </div>
            <SignIn
              signType="google"
              style={{ marginBottom: "19px" }}
            />
            <SignIn
              signType="facebook"
              style={{ marginBottom: "19px" }}
            />
            <SignIn
              signType="twitter"
              style={{ marginBottom: "15px" }}
            /> */}
          </form>
        </div>
        <h5 className={styles.bottomText}>
          Hesabın yok mu? <span><Link to="/register"  className={styles.bottomLink}>Kayıt ol.</Link></span>
        </h5>
      </div>
    </div>
  );
}

export default LoginContainer;
