import Hero from "../../components/Hero";
import Footer from "../../components/Footer";
import ReviewContent from "../../components/ReviewContent";
import About from "../../components/About";

const Home = () => {
  return (
    <div style={{height:"100%"}}>
      <Hero />
      <About />
      <Footer />
    </div>
  );
};

export default Home;
