import { get,post} from "./request"

export const getPlaceWithId = place_id => get(`/detail_place/${place_id}`);

export const getPlaces = (type,username) => get(`/${type}/${username}`);

export const addWantedToGo = data => post("/go",data);
export const addVisited = data => post("/visited",data);
export const addFav = data => post("/fav",data);

