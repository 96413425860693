import styles from "./index.module.css";

import { Link } from "react-router-dom";

import dayjs from "dayjs";
import localeTr from "dayjs/locale/tr";

import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);
dayjs.locale(localeTr);


function ProfileList({ title, items ,path}) {
  return (
    <div className={styles.listContainer}>
      <div className={styles.listHeader}>
        <h4>{title}</h4>
        <h6><Link to={path ? path : "/"}>HEPSİNİ GÖR</Link></h6>
      </div>
      {items &&
        items.map((item,index) => {
          return (
            <Link className={styles.listItem} key={index} to={`/place/${item?.places?.id}`}>
              <img
                src={item?.places?.photo_link}
                alt=""
              />
              <div className={styles.listItemContent}>
                <h3>{item?.places?.place_name}</h3>
                <h5>
                <span>· {dayjs(item?.places?.created_at).fromNow()}</span>
                </h5>
              </div>
            </Link>
          );
        })}
      {!items && (
        <>
          <div className={styles.listItem}>
            <img
              src="https://blog.biletbayi.com/wp-content/uploads/2018/08/kapadokya-turkiye-840x420.jpg"
              alt=""
            />
            <div className={styles.listItemContent}>
              <h3>Kapadokya</h3>
              <h5>
                Nurullah Kılıç <span>· bir saat önce</span>
              </h5>
            </div>
          </div>
          <div className={styles.listItem}>
            <img
              src="https://www.cumhuriyet.com.tr/Archive/2021/7/4/1849727/kapak_174441.jpg"
              alt=""
            />
            <div className={styles.listItemContent}>
              <h3>Galata Kulesi</h3>
              <h5>
                Nurullah Kılıç <span>· bir saat önce</span>
              </h5>
            </div>
          </div>
          <div className={styles.listItem}>
            <img
              src="https://www.yerelnet.org.tr/wp-content/uploads/2020/02/oludeniz.jpg"
              alt=""
            />
            <div className={styles.listItemContent}>
              <h3>Ölü Deniz</h3>
              <h5>
                Nurullah Kılıç <span>· bir saat önce</span>
              </h5>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default ProfileList;
