import "./index.css";

import TurkeyLogo from "../../assets/turkey-logo.svg";

function Footer() {
  return (
    <div className="footer">
      <img src={TurkeyLogo} alt="TurkeyLogo" />

      <a href="https://goturkiye.com/">
        <p>GoTürkiye Sitesini Ziyaret Edin</p>
      </a>
    </div>
  );
}

export default Footer;
