import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import TurkeyLogo from "../../assets/turkey-logo.svg";
import { FaCompass } from "react-icons/fa";

import "./index.css";
import LocationData from "../../assets/dumy.json";
import SearchBar from "../../shared/SearchBar";

import { useAuth } from "../../context/AuthContext";

const Navbar = () => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();

  const [click, setClick] = useState(false);
  const [dropdown, setDropdown] = useState(false);

  const handleClick = () => setClick(!click);
  const handleLogout = () => {
    logout();
    navigate("/", { replace: true });
  };

  return (
    <div className="navbar">
      <div className="NavbarContainer">
        <div className="left-side">
          <Link to="/">
            <img src={TurkeyLogo} alt="TurkeyLogo" className="turkey-logo" />
          </Link>

          <div className="search-bar-container">
            <div className="search-bar-inner">
              <SearchBar data={LocationData} />
            </div>
          </div>
        </div>

        <div className="right-side">
          <Link to="/pusula">
            <button className="get-recommendations">
              <div className="compass-button-inner">
                <div className="compass-icon-inner">
                  <FaCompass />
                </div>
                <p>Yer Önerisi Al</p>
              </div>
            </button>
          </Link>

          <ul className={click ? "nav active" : "nav"}>

            <div className="text-button">
              <Link to={`/`}>
                <h1>ANASAYFA</h1>
              </Link>
            </div>
            <div className="text-button">
              <Link to={`/register`}>
                <h1>KAYIT OL</h1>
              </Link>
            </div>

            <div className="text-button">
              <Link to={`/login`}>
                <h1>GİRİŞ YAP</h1>
              </Link>
            </div>

            <h1 className="text-button">TÜRKİYE DENEYİMLERİ</h1>
            <h1 className="text-button">GOTÜRKİYE TV</h1>
            <h1 className="text-button">MARKALAŞMA VE HALKLA İLİŞKİLER</h1>
            <div className="language-selection">

              <div className="language-selection-dropdown">
                <div className="language-selection-dropdown-list">
                  <a href="https://goturkiye.com/homepage">
                    <li>English</li>{" "}
                  </a>
                  <a href="https://goturkiye.com/tr/anasayfa">
                    <li>Türkçe</li>
                  </a>
                  <a href="https://goturkiye.com/ru/homepage">
                    <li>русский язык</li>
                  </a>
                  <a href="https://goturkiye.com/ua/homepage">
                    <li>українська</li>
                  </a>
                  <a href="https://goturkiye.com/de/homepage">
                    <li>Deutsch</li>
                  </a>
                  <a href="https://goturkiye.com/fr/homepage">
                    <li>Français</li>
                  </a>
                  <a href="https://goturkiye.com/es/homepage">
                    <li>español, castellano</li>
                  </a>
                  <a href="https://goturkiye.com/ar/homepage">
                    <li>العربية</li>
                  </a>
                  <a href="https://goturkiye.com/fa/homepage">
                    <li>فارسی</li>
                  </a>
                  <a href="https://goturkiye.com/zh/homepage">
                    <li>中文 (Zhōngwén), 汉语, 漢語</li>
                  </a>
                </div>
              </div>
            </div>
          </ul>

          {/* Dil Seçim */}

          {/* hamburger menü */}
          {user.length !== 0 ? (
            <div className="dropdown">
              <div
                className="dropbtn menu-button user"
                onClick={() => {
                  setDropdown(!dropdown);
                }}
              >
                <img
                  src={user?.profile_photo === null ?`https://avatars.dicebear.com/api/initials/${user.user_name}.svg` : process.env.REACT_APP_API_URL + "/" + user.profile_photo}
                  alt={user.name}
                  loading="lazy"
                />
                <span className="menu-button-label">{user?.user_name}</span>
              </div>
              <div
                id="myDropdown"
                className={`dropdown-content ${dropdown ? "show" : ""}`}
              >
                <Link to={`/profile/${user.user_name}`}>Profile git</Link>
                <Link to={"/"} onClick={handleLogout}>
                  Çıkış yap
                </Link>
              </div>
            </div>
          ) : null}
          <div className="menu-button" onClick={handleClick}>
            <span className="hamburger">
              {click ? (
                <AiOutlineClose className="icon" />
              ) : (
                <AiOutlineMenu className="icon" />
              )}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
