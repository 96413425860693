import styles from "./index.module.css";
import categories from "../../Pusula/CategoryIcon/categories";
import { Link } from "react-router-dom";

const SuggestionPlace = ({id, categoryId, place, desc, image}) => {
  const category = categories.find((category)=>category.id===categoryId);
  return (
    <div className={styles.placeContainer} draggable>
        <img src={image} alt={place} draggable="false" loading="lazy"/>
        <Link to={`/place/${id}`} className={styles.placeContent}>
            <div className={styles.bottomSection}>
                <div className={styles.textSide}>
                    <h2>{place || "Ölü Deniz"}</h2>
                    <h4>{desc.length > 155
                          ? desc.slice(0, 155) + "..."
                          : desc}</h4>
                </div>
                <div className={styles.iconSide}>{category.icon}</div>
            </div>

        </Link>
    </div>
  )
}

export default SuggestionPlace;