import LoginContent from "../../components/LoginContent";
import { CloseButton } from "../../shared/Button";
import { useNavigate } from "react-router-dom";
import { Toaster } from "react-hot-toast";

import Modal from "react-modal";
import { useState } from "react";

import "./index.css";

const customStyles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 99999999,
    backgroundColor: "rgba(0,0,0, .69)",
  },
  content: {
    position: "absolute",
    width: "932px",
    height: "703px",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    border: "none",
    background: "transparent",
    overflowY: "auto",
    overflowX: "hidden",
    borderRadius: "5px",
    outline: "none",
    padding: 0,
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.38)",
    zIndex: 999999999,
  },
};

function Login() {
  const [loginModal, setLoginModal] = useState(true);

  const navigate = useNavigate();

  function closeModal() {
    navigate(-1);
    setLoginModal(false);
  }

  return (
    <Modal
      isOpen={loginModal}
      ariaHideApp={false}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <Toaster position="bottom-center" reverseOrder={false} />
      <LoginContent setLoginModal={setLoginModal} />
      <CloseButton onClick={closeModal} />
    </Modal>
  );
}

export default Login;
