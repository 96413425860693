import styles from "./index.module.css";

function Input({ isError, errorText, labelText, ...inputOptions }) {
  return (
    <div className={styles.inputGroup}>
      <label className={styles.label}>{labelText}</label>
      <input
        className={`${styles.Input} ${isError ? styles.InputError : ""}`}
        {...inputOptions}
      />
    </div>
  );
}

export default Input;
