import Modal from "react-modal";
import { useState } from "react";

import { CloseButton } from "../../shared/Button";
import Rate from "../../shared/StarRating"
import profileimg from "../../assets/profileimg.svg"

import styles from "./index.module.css";


const customStyles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 99999999,
    backgroundColor: "rgba(0,0,0, .60)",
    backdropFilter: "blur(7px)",
    transition: "300ms all",
  },
  content: {
    position: "absolute",
    display: "flex",
    width: "970px",
    height: "700px",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    border: "none",
    background: "transparent",
    overflowY: "auto",
    overflowX: "hidden",
    borderRadius: "5px",
    outline: "none",
    padding: 0,
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.38)",
    zIndex: 999999999,
  },
};

function LocationSelector() {
  const [modal, setModal] = useState(true);


  return (
    <Modal
      isOpen={modal}
      ariaHideApp={false}
      onRequestClose={() => setModal(false)}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <div className={styles.container}>
        {/* <div className={styles.placeTitle}>
            <p>Galata Kulesi</p>
            <div className={styles.rate}>
                <Rate rating={3} />
            </div>
      </div> */}

        <div className={styles.detailContent}>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            Maecenas at ex a arcu convallis sagittis. Aliquam eget neque libero.
            Aenean commodo lorem sed dapibus egestas. Nullam tristique pellentesque orci,
            id cursus velit maximus non.Vivamus cursus mollis gravida. Vestibulum ac orci
            sed arcu accumsan commodo. Nullam ut gravida dolor. Sed pellentesque tempus nulla,
            non finibus tortor vestibulum at.<br></br> Aenean sollicitudin commodo elementum.
            Nunc dui leo, porttitor nec velit at, vulputate consectetur magna. Donec ut massa eu augue bibendum ornare in sit amet lacus. Cras facilisis metus ac cursus posuere.<br></br><br></br>

            Maecenas pellentesque vitae metus quis tincidunt. Nunc ac est fermentum, maximus arcu in, pellentesque sapien. Vestibulum vulputate magna vitae leo dictum, quis egestas felis convallis. Nunc posuere lacinia consequat. Aliquam egestas dolor eu aliquam eleifend. Nunc luctus feugiat risus eu condimentum. In et felis cursus, bibendum quam et, ultricies purus. Ut orci lacus, malesuada a pellentesque a, hendrerit et ipsum. Suspendisse ut accumsan mi, vitae blandit mauris. Proin nec ante suscipit, aliquet nisl cursus, porta lorem. Maecenas finibus cursus massa pellentesque volutpat. Cras nec vulputate ligula, posuere commodo mauris. Nullam eu rutrum turpis. Curabitur maximus in urna nec ornare.<br></br><br></br>

            In id lectus ullamcorper, interdum lorem sed, vehicula magna. Vestibulum mauris nisl, vestibulum sed porta ut, fermentum eu turpis. Sed dapibus dolor et nisi viverra vehicula. Maecenas facilisis elit ac arcu placerat, quis fermentum purus consectetur. Nunc gravida tristique ornare. Nulla imperdiet tincidunt dui non imperdiet.
          </p>
          <div className={styles.bottomContainer}>
          
            <div className={styles.profileContent}>
              <img src={profileimg} alt="Profileimg" />
              <h3> Helin Biçen </h3>
              <h2> · bir saat önce </h2>
            </div>

            <div className={styles.rate}>
              <Rate rating={3} />
            </div>

          </div>

        </div>
      </div>
      <CloseButton onClick={() => setModal(false)} />
    </Modal>
  );
}

export default LocationSelector;
