import "./index.module.css";
import styles from "./index.module.css";

import ReviewContent from "../../components/ReviewContent";

import SearchBarDemo from "../../shared/SearchBarDemo";
import LocationData from "../../assets/dumy.json";




const MyReviews = () =>{


    return(
        <div className={styles.container}>
        <div className={styles.myReviewsContainer}>
            
            <div className={styles.title}>
                <h3>DEĞERLENDİRMELERİM</h3>
            </div>

            <div className={styles.SearchBarDemo}>
            <SearchBarDemo data={LocationData} placeholderText="Yorumlarını buradan arayabilirsin"/>
            </div>

            <div className={styles.reviewContent}>
                  <ReviewContent borderShow={true}/>
                  <ReviewContent borderShow={true}/>
                  <ReviewContent borderShow={true}/>
            </div>

        </div>
        </div>


    );
};

export default MyReviews;