import { useEffect } from 'react';
import StarRatings from 'react-star-ratings';
import "./index.css"

function Rate({ rating,changeRate,starEmptyColor,starRatedColor,starHoverColor, ...props }) {

  return (
    <div className='star-ratings'>
          <StarRatings
      rating={rating}
      changeRating={changeRate}
      numberOfStars={5}
      name='rating'
      starDimension= {window.screen.availWidth  <= 830 ? "12px" : "22px"}
      starSpacing="1px"
      svgIconViewBox="0 0 24 24"
      svgIconPath="M21.947 9.179a1.001 1.001 0 0 0-.868-.676l-5.701-.453-2.467-5.461a.998.998 0 0
       0-1.822-.001L8.622 8.05l-5.701.453a1 1 0 0 0-.619 1.713l4.213 4.107-1.49 6.452a1 1 0 0 0
        1.53 1.057L12 18.202l5.445 3.63a1.001 1.001 0 0 0 1.517-1.106l-1.829-6.4
         4.536-4.082c.297-.268.406-.686.278-1.065z"
      starEmptyColor={starEmptyColor ? starEmptyColor :"#7D7D7D" }
      starRatedColor={starRatedColor ? starRatedColor :"#fed106" }
      starHoverColor={starHoverColor ? starHoverColor :"#fed106" }
      {...props}

    />
    </div>

  );
}


export default Rate