import RegisterContent from "../../components/RegisterContent";
import { CloseButton } from "../../shared/Button";

import Modal from "react-modal";
import { useState } from "react";
import { Toaster } from "react-hot-toast";

import { useNavigate } from "react-router-dom";

import "./index.css";

const customStyles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 999999999,
    backgroundColor: "rgba(0,0,0, .69)",
  },
  content: {
    position: "absolute",
    width: "932px",
    height: "703px",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    border: "none",
    background: "transparent",
    overflowY: "auto",
    overflowX: "hidden",
    borderRadius: "5px",
    outline: "none",
    padding: 0,
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.38)",
    zIndex: 999999999,
  },
};

function Register() {
  const navigate = useNavigate();
  const [modalIsOpen, setIsOpen] = useState(true);


  function closeModal() {
    navigate(-1);
  }

  return (
      <Modal
        isOpen={modalIsOpen}
        ariaHideApp={false}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >

        <Toaster position="top-left" reverseOrder={false} />
        <RegisterContent />
        <CloseButton onClick={closeModal} />
      </Modal>
  );
}

export default Register;
