import styles from "./index.module.css";

import SearchBarDemo from "../../shared/SearchBarDemo";

import PlaceCard from "../../shared/PlaceCard";

export default function ListDetailPage({
  listName,
  listUniquePlaceholder,
  places,
}) {
  return (
    <div className={styles.container}>
      <div className={styles.contentContainer}>
        <div className={styles.content}>
          <div className={styles.title}>
            <h1>{listName}</h1>
          </div>

          <SearchBarDemo placeholderText={listUniquePlaceholder} />

          <div className={styles.places}>
            <div className={styles.PlaceCardInner}>
              {/* <h1>Favorilerin burada gözükecek</h1> */}
              <div className={styles.PlaceCardContainer}>
                {places?.map((place) => {
                  return (
                    <PlaceCard
                      key={place.id}
                      id={place.id}
                      image={place.places.photo_link}
                      placeName={
                        place.places.place_name.length > 21
                          ? place.places.place_name.slice(0, 21) + "..."
                          : place.places.place_name
                      }
                      desc={
                        place.places.description.length > 142
                          ? place.places.description.slice(0, 142) + "..."
                          : place.places.description
                      }
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
