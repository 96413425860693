import styles from "./index.module.css";

function LocationDropdown({ data }) {
  return (
    <div className={styles.dataResult}>
      {data.slice(0, 15).map((value, key) => {
        return (
          <a
            className={styles.dataItem}
            href={value.link + "/" + value.title.toLowerCase()}
          >
            <p>{value.title} </p>
          </a>
        );
      })}
    </div>
  );
}

export default LocationDropdown;
