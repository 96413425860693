import "./index.css";
import PlaceDetailPhoto from "../../assets/place-detail-photo.jpg";
import { FaPlus, FaCheck, FaHeart, FaComment } from "react-icons/fa";
import Star from "../../assets/Star.svg";
import PlaceInfo from "../../components/PlaceInfo/index";
import ReviewContent from "../../components/ReviewContent/index";
import PlaceCard from "../../shared/PlaceCard";
import { useState } from "react";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional

const PlaceStatic = () => {
  const [visible, setVisible] = useState(true);
  const show = () => setVisible(true);
  const hide = () => setVisible(false);
  // const [listClick, setListClick] = useState();

  const ListIcon = ({ icon }) => {
    const [listClick, setListClick] = useState(false);
    const handleListClick = () => setListClick(!listClick);

    return (
      <div
        className={listClick ? "list-icon active" : "list-icon"}
        onClick={handleListClick}
      >
        <div className="list-icon">{icon}</div>
      </div>
    );
  };

  return (
    <div className="place-detail-container">
      <div className="place-detail-content-inner">
        <div className="place-detail-content">
          <div className="place-detail">
            <div className="place-photo-container">
              <img
                src="https://www.masallaroku.com/wp-content/uploads/2020/12/galata-kulesi.jpg"
                alt=" "
                className="place-detail-photo"
              />

              <div className="place-detail-inner">
                <div className="place-name-inner">
                  <h1 className="place-name">Galata Kulesi</h1>
                  <div className="rating">
                    <img src={Star} alt=" " className="star-icon" />
                    <p>4.3/5</p>
                  </div>
                </div>

                <div className="list-icons">
                  <Tippy content="Gitmek İstediklerime Ekle">
                    <ListIcon icon={<FaPlus />} />
                  </Tippy>
                  <Tippy content="Gittiklerime Ekle">
                    <ListIcon icon={<FaCheck />} />
                  </Tippy>
                  <Tippy content="Favorilerime Ekle">
                    <ListIcon icon={<FaHeart />} />
                  </Tippy>
                  <Tippy content="Yorum yap">
                    <ListIcon icon={<FaComment />} />
                  </Tippy>

                  {/* <Tippy content="Gitmek İstediklerime Ekle">
                    <div className={listClick === 0 ? 'plus-icon active' : 'plus-icon'}
        onClick={() => setListClick(0)}>
                      <FaPlus />
                    </div>
                  </Tippy>
                  <Tippy content="Gittiklerime Ekle">
                    <div className={listClick === 1 ? 'check-icon active' : 'check-icon'}
        onClick={() => setListClick(1)}>
                      <FaCheck />
                    </div>
                  </Tippy>
                  <Tippy content="Favorilerime Ekle">
                    <div className={listClick === 2 ? 'heart-icon active' : 'heart-icon'}
        onClick={() => setListClick(2)}>
                      <FaHeart />
                    </div>
                  </Tippy>

                  <Tippy content="Yorum Ekle">
                    <div className={listClick === 3 ? 'comment-icon active' : 'comment-icon'}
        onClick={() => setListClick(3)}>
                      <FaComment />
                    </div>
                  </Tippy> */}
                </div>
              </div>
            </div>

            <div className="place-info-slider">
              <PlaceInfo />
            </div>

            <div className="additional-content">
              <div className="reviews">
                <div className="reviews-header">
                  <h1>Galata Kulesi Hakkındaki Yorumlar</h1>
                </div>
                <div className="review-content">
                  <ReviewContent />
                  <ReviewContent />
                  <ReviewContent />
                </div>
              </div>
              <div className="recommodations">
                <div className="recommodations-header">
                  <h1>Diğer Öneriler</h1>
                </div>

                <div className="recommended-places">
                  <PlaceCard />
                  <PlaceCard />
                  <PlaceCard />
                  <PlaceCard />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlaceStatic;
