import { Toaster } from "react-hot-toast";
import { Outlet, Route,Routes} from "react-router-dom";
import Navbar from "../../components/Navbar";
import Register from "../Register";

const Layout = () => {
  return (
    <>
    <Toaster/>
    <Navbar />
    <Outlet/>
    </>
  );
};

export default Layout;
