import { Navigation, Pagination, Scrollbar, A11y } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";
import "./index.css";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

const PlaceInfo = ({ content }) => {
  return (
    <Swiper
      className="info-container"
      modules={[Navigation, Pagination, Scrollbar, A11y]}
      slidesPerView={1}
      navigation
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
    >
      <SwiperSlide>
        <p className="info-text">{content}</p>
      </SwiperSlide>
      {/* <SwiperSlide>
        <p className="info-text">Slide 2</p>
      </SwiperSlide>
      <SwiperSlide>
        <p className="info-text">Slide 3</p>
      </SwiperSlide>
      <SwiperSlide>
        <p className="info-text">Slide 4</p>
      </SwiperSlide> */}
    </Swiper>
  );
};

export default PlaceInfo;
