import "./index.css";
import AboutCard from "./AboutCard";
import TurkeyCollage from "../../assets/turkey-collage.jpg";
import { FaCalendar, FaList, FaThinkPeaks, FaComment } from "react-icons/fa";
import { Link } from "react-router-dom";



const index = () => {
  return (
    <div className="about-all-container">
      <div className="about">
        <div className="about-inner">
          <div className="about-container">
            <div className="turkey-collage-container">
              <img
                src={TurkeyCollage}
                alt=""
                className="turkey-collage-photo"
              />
              <h1 className="turkey-collage-text">
                Ziyaret ettiğin yerlerin listesini tut. Gitmek istediklerini ve
                favorilerini listele. Herkesle paylaş.
              </h1>
            </div>

            <Link className="about-btn" to="/register">
              Hemen Üye Ol
            </Link>

            <p>Tamamen ücretsiz.</p>

            <div className="discover">
              <div className="discover-header">
                <h2>Keşfet, deneyimle, tadını çıkar.</h2>
              </div>
              <div className="discover-text">
                <p>
                  <span>Pusula</span>, seyahatin boyunca sana her konuda
                  rehberlik etmeyi amaçlar Bu macerada en iyi arkadaşın{" "}
                  <span>Pusula</span> olacaktır.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="about-card-inner">
        <div className="about-card-container">
          <div className="about-card">
            <AboutCard
              icon={<FaThinkPeaks className="about-card-icon" />}
              heading="Sana özel öneriler"
              text="Bulunduğun konuma göre veya gitmek istediğin yere göre sadece sana özel
            mekan önerileri al."
            />
          </div>
          <div className="about-card">
            <AboutCard
              icon={<FaList className="about-card-icon" />}
              heading="Listeler oluştur"
              text="Gitmek istediğin, gittiğin ve favori yerleri listele. İstediğin zaman
            görüntüle
"
            />
          </div>{" "}
          <div className="about-card">
            <AboutCard
              icon={<FaComment className="about-card-icon" />}
              heading="Puanla, yorum yap ve paylaş"
              text="Gittiğin yerler hakkında yorum yaparak anılarını taze tut ve
            insanlara fikir vererek topluluğun gelişmesini sağla.
            "
            />
          </div>
          <div className="about-card">
            <AboutCard
              icon={<FaCalendar className="about-card-icon" />}
              heading="Seyahat geçmişini görüntüle"
              text="Nerede ne zaman bulunduğunu görüntülemek çok kolay."
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default index;
