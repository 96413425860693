import Modal from "react-modal";
import { motion } from "framer-motion";
import { useState } from "react";

import { Button, CloseButton } from "../../shared/Button";
import SearchContent from "./SearchContent";
import LocationData from "../../assets/dumy.json";

import styles from "./index.module.css";
import LocationPin from "../../assets/location-pin.svg";

const customStyles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 99999999,
    backgroundColor: "rgba(0,0,0, .60)",
    backdropFilter: "blur(7px)",
    transition: "300ms all",
  },
  content: {
    position: "absolute",
    width: "594px",
    height: "387px",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    border: "none",
    background: "transparent",
    overflowY: "auto",
    overflowX: "hidden",
    borderRadius: "5px",
    outline: "none",
    padding: 0,
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.38)",
    zIndex: 999999999,
  },
};

function LocationSelector({modal = false, setModal, selectedCity, setSelectedCity}) {
  const [selectorToggle, setSelectorToggle] = useState(false);

  const variants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  return (
    <Modal
      isOpen={modal}
      ariaHideApp={false}
      onRequestClose={() => setModal(false)}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <div className={styles.container}>
        <img src={selectedCity?.photo} alt="CityImage" />
        <div className={styles.rightSide}>
          <motion.div
            initial="hidden"
            animate="visible"
            variants={variants}
            className={
              !selectorToggle ? styles.selectedText : styles.selectedTextHidden
            }
          >
            <h5>Şu anki konumun:</h5>
            <div className={styles.currentLocation}>
              <img src={LocationPin} alt="LocationPin" />
              <h2>{selectedCity?.city_name}</h2>
            </div>
          </motion.div>
          <Button
            as={motion.button}
            animate={{ opacity: 0.5 }}
            onClick={() => {
              setSelectorToggle(!selectorToggle);
            }}
            text="Farklı konum seç"
            theme="primary"
          />
          {!selectorToggle ? null : <SearchContent data={LocationData} setModal={setModal} selectedCity={selectedCity} setSelectedCity={setSelectedCity}/>}
          <Button
            as={motion.button}
            animate={{ opacity: 0.5 }}
            text="Devam et"
            theme="secondary"
            className={!selectorToggle ? null : styles.hidden}
            onClick={() => setModal(false)}
          />
        </div>
      </div>
      <CloseButton onClick={() => setModal(false)} />
    </Modal>
  );
}

export default LocationSelector;
