import "./index.css";

function AboutCard(props) {
    return (
      <>
        <div className="about-card-icon-container">
          {props.icon}
        </div>
        <h3>{props.heading}</h3>
        <p>{props.text}</p>
      </>
    );
  }
  
  export default AboutCard;