import "./index.css";

import { FaCompass } from "react-icons/fa";

const index = () => {
  return (
    <div className="loading-page">
      <h1 className="loading-text">
        <span></span>
      </h1>

      <a href="/pusula">
        <div className="compass-logo">
          <FaCompass />
        </div>
      </a>
    </div>
  );
};

export default index;
