import "./index.css";
import { FaPlus, FaCheck, FaHeart, FaComment } from "react-icons/fa";
import Star from "../../assets/Star.svg";

import PlaceInfo from "../../components/PlaceInfo/index";
import ReviewContent from "../../components/ReviewContent/index";
import PlaceCard from "../../shared/PlaceCard";
import CommentPopUp from "../../shared/CommentPopUp";

import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional
import toast, {Toaster} from "react-hot-toast";

import { useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import { PlaceServices } from "../../services";

const ListIcon = ({ icon, tippyContent , ...props}) => {
  const [listClick, setListClick] = useState(false);
  const handleListClick = () => setListClick(!listClick);

  return (
    <Tippy content={tippyContent} >
      <div
        className={listClick ? "list-icon active" : "list-icon"}
        onClick={handleListClick}
        {...props}
      >
        <div className="list-icon">{icon}</div>
      </div>
    </Tippy>
  );
};

const PlaceDetail = () => {
  const [openModal,setModal] = useState(false);

  let { place_id } = useParams();

  console.log(place_id);

  const { isLoading, isError, data, error } = useQuery(
    ["place-detail", place_id],
    async () => {
      return await PlaceServices.getPlaceWithId(place_id)
        .then((data) => data)
        .catch((err) => err);
    }
  );

  const bodyData ={
    "place_id":place_id
  }

  const addWanted = ()=>{
    PlaceServices.addWantedToGo(bodyData)
    .then(data=>{
      toast.success("Gitmek istediklerine başarıyla eklendi.")
    })
    .catch(err=>{
      toast.error(err)
    })
  }

  const addVisited = ()=>{
    PlaceServices.addVisited(bodyData)
    .then(data=>{
      toast.success("Gittiklerine başarıyla eklendi.")
    })
    .catch(err=>{
      toast.error(err)
    })
  }

  const addFav = ()=>{
    PlaceServices.addFav(bodyData)
    .then(data=>{
      toast.success("Favorilerine başarıyla eklendi.")
    })
    .catch(err=>{
      toast.error(err)
    })
  }
  return (
    <>
    {openModal && <CommentPopUp openModal={openModal} setModal={setModal} place_name={data?.place_name} place_id={place_id}/>}
    <div className="place-detail-container">
     <Toaster position="bottom-center" />
      <div className="place-detail-content-inner">
        <div className="place-detail-content">
          <div className="place-detail">
            <div className="place-photo-container">
              <img
                src={data?.photo_link}
                alt={data?.place_name}
                className="place-detail-photo"
                />

              <div className="place-detail-inner">
                <div className="place-name-inner">
                  <h1 className="place-name">{data?.place_name}</h1>
                  <div className="rating">
                    <img src={Star} alt=" " className="star-icon" />
                    <p>{data?.rate !== 0 ? data?.rate : "-" }/5</p>
                  </div>
                </div>

                <div className="list-icons">
                  <ListIcon
                    icon={<FaPlus />}
                    onClick={addWanted}
                    tippyContent="Gitmek İstediklerime Ekle"
                    />
                  <ListIcon
                    icon={<FaCheck />}
                    onClick={addVisited}
                    tippyContent="Gittiklerime Ekle"
                    />
                  <ListIcon
                    icon={<FaHeart />}
                    onClick={addFav}
                    tippyContent="Favorilerime Ekle"
                    />
                  <ListIcon icon={<FaComment />} tippyContent="Yorum yap" onClick={()=>{setModal(true)}}/>
                </div>
              </div>
            </div>

            <div className="place-info-slider" >
              <PlaceInfo content={data?.description}/>
            </div>

            <div className="additional-content">
              <div className="reviews">
                <div className="reviews-header">
                  <h1>{data?.place_name} Hakkındaki Yorumlar</h1>
                  <p>TÜMÜNÜ GÖR</p>
                </div>
                <div className="review-content">
                {data?.comments?.length !== 0 &&  data?.comments?.slice(0).reverse().map((comment, index)=>{
                  return <ReviewContent key={index} content={comment?.content} timeAgo={comment?.created_at} userId={comment?.users?.id} user={comment?.users} rate={comment?.rate} userImage={comment?.users.profile_photo === null ?`https://avatars.dicebear.com/api/initials/${comment?.users?.user_name}.svg` : process.env.REACT_APP_API_URL + "/" + comment?.users.profile_photo}/>;
                })}
                {data?.comments?.length === 0 && <ReviewContent content="Bu mekan hakkında herhangi bir yorum bulunmuyor!" />}
                </div>
              </div>
              <div className="recommodations">
                <div className="recommodations-header">
                  <h1>Diğer Öneriler</h1>
                </div>

                <div className="recommended-places">
                  {data?.Recommended.map((place)=>{
                    return(<PlaceCard 
                      key={place.id}
                      id={place.id}
                      image={place.photo_link}
                      placeName={place.place_name.length >21 ? place.place_name.slice(0,21) + "..." : place.place_name}
                      cityName={data?.city?.city_name}
                      desc={place.description.length >142 ? place.description.slice(0,142) + "..." : place.description}
                    />);
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default PlaceDetail;
