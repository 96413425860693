import { useState , useEffect} from "react";

const CategoryIcon = ({ icon, type, category, setCategory }) => {
  const [categoryClick, setCategoryClick] = useState(false);
  const handleCategoryClick = () => {
    // setCategoryClick(!categoryClick);
    if(!category.includes(type)){
      setCategory([type,...category]);
      setCategoryClick(true);
    }
    else{
      setCategory(category.filter((element)=>element!==type))
      setCategoryClick(!categoryClick);
    }
  };

  useEffect(()=>{
    if(category.includes(type))
      setCategoryClick(true);
    else
      setCategoryClick(false)
  },[category])

  return (
    <div className="category-container">
      <div
        className={categoryClick ? "category active" : "category"}
        onClick={handleCategoryClick}
      >
        <div className="category-icon">{icon}</div>
      </div>
      <h1 style={{ color: "white" }}>{type}</h1>
    </div>
  );
};

export default CategoryIcon;
