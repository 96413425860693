import { get , post } from "./request"

export const getUserWithId = user_id => get(`/user/${user_id}`);
export const getUserWithUserName = user_name => get(`/user/${user_name}`);
export const changeUserProfile = data => post(`/change_pp`, data);
export const changeUserBanner = data => post(`/change_cp`, data);







