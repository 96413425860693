import styles from "./index.module.css";
import "../../assets/color.css";

import GoogleLogo from "../../assets/google.svg";
import FacebookLogo from "../../assets/facebook.svg";
import TwitterLogo from "../../assets/twitter.svg";
import CloseButtonIcon from "../../assets/close-button.svg";

function Button({ theme, text, className, ...buttonOptions }) {
  return (
    <button className={styles.button + " " + (className ? className : "") + " " + (theme ? theme : "") } {...buttonOptions}>
      {text}
    </button>
  );
}

function SignIn({ signType, text, className, ...buttonOptions }) {
  if (signType === "google") {
    return (
      <button className={styles.buttonSign} {...buttonOptions}>
        <div>
          <img src={GoogleLogo} alt="GoogleLogo" />
          <h3>Google ile giriş yap</h3>
        </div>
      </button>
    );
  }
  if (signType === "facebook") {
    return (
      <button className={styles.buttonSign} {...buttonOptions}>
        <div>
          <img src={FacebookLogo} alt="FacebookLogo" />
          <h3>Facebook ile giriş yap</h3>
        </div>
      </button>
    );
  }
  if (signType === "twitter") {
    return (
      <button className={styles.buttonSign} {...buttonOptions}>
        <div>
          <img src={TwitterLogo} alt="TwitterLogo" />
          <h3>Twitter ile giriş yap</h3>
        </div>
      </button>
    );
  }
  return (
    <button className={styles.button + " " + className} {...buttonOptions}>
      {text}
    </button>
  );
}

function CloseButton({ ...buttonOptions }) {
  return (
    <button className={styles.closeButton} {...buttonOptions}>
      <img src={CloseButtonIcon} alt="CloseButtonIcon" />
    </button>
  );
}
export { SignIn, Button, CloseButton };
