import styles from "./index.module.css";

import { FaCalendar } from "react-icons/fa";

function TripHistoryWidget() {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
          <span>Seyahat Geçmişi</span>
          <span>HEPSİNİ GÖR</span>
      </div>
      <div className={styles.content}>
        <div className={styles.month}>
          <div className={styles.icon}>
            <FaCalendar />
            <h5>OCA</h5>
          </div>
          <div className={styles.placeItems}>
            <div className={styles.place}>
              <span>30</span>
              Galata Kulesi
            </div>
          </div>
        </div>
        <div className={styles.month}>
          <div className={styles.icon}>
            <FaCalendar />
            <h5>ARA</h5>
          </div>
          <div className={styles.placeItems}>
            <div className={styles.place}>
              <span>1</span>
              Galata Kulesi
            </div>
            <div className={styles.place}>
              <span>6</span>
              Galata Kulesi
            </div>
            <div className={styles.place}>
              <span>12</span>
              Galata Kulesi
            </div>
            <div className={styles.place}>
              <span>29</span>
              Galata Kulesi
            </div>
            <div className={styles.place}>
              <span>33</span>
              Galata Kulesi
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TripHistoryWidget;
