export const users = [
  {
    id: 1,
    name: "Nurullah Kılıç",
    username: "nurullah",
    email: "Sincere@april.biz",
    profileImage: "https://www.vodvilapp.com/static/uploads/2_profile.jpg",
    bannerImage: "https://www.vodvilapp.com/static/uploads/7_banner.jpg",
    comments: [
      {
        id: 1,
        placeTitle: "Galata Kulesi",
        rate: 3,
        content:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas at ex a arcu convallis sagittis. Aliquam eget neque libero. Aenean commodo lorem sed dapibu s egestas. Nullam tristique pellentesque orci, id cursus v elit maximus non. Vivamus cursus mollis gravida. Vestibulum ac orci sed arcu accumsan commodo. Nullam ut gravida dolor. Sed pellentesque tempus nulla, non finibus tortor vestibulum at. Aenean sollicitudin commodo elementum. Nunc dui leo, porttitor nec velit at...",
        authorId: 1,
        authorImage: "https://www.vodvilapp.com/static/uploads/2_profile.jpg",
        authorName: "Nurullah Kılıç",
        authorUserName: "nurullah",
        timeAgo: "bir saat önce",
      },
      {
        id: 2,
        placeTitle: "Gebze Kulesi",
        rate: 2.5,
        content:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas at ex a arcu convallis sagittis. Aliquam eget neque libero. Aenean commodo lorem sed dapibu s egestas. Nullam tristique pellentesque orci, id cursus v elit maximus non. Vivamus cursus mollis gravida. Vestibulum ac orci sed arcu accumsan commodo. Nullam ut gravida dolor. Sed pellentesque tempus nulla, non finibus tortor vestibulum at. Aenean sollicitudin commodo elementum. Nunc dui leo, porttitor nec velit at...",
        authorId: 1,
        authorImage: "https://www.vodvilapp.com/static/uploads/2_profile.jpg",
        authorName: "Nurullah Kılıç",
        authorUserName: "nurullah",
        timeAgo: "bir saat önce",
      },
      {
        id: 3,
        placeTitle: "Esenyurt Köşkü",
        rate: 0.5,
        content:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas at ex a arcu convallis sagittis. Aliquam eget neque libero. Aenean commodo lorem sed dapibu s egestas. Nullam tristique pellentesque orci, id cursus v elit maximus non. Vivamus cursus mollis gravida. Vestibulum ac orci sed arcu accumsan commodo. Nullam ut gravida dolor. Sed pellentesque tempus nulla, non finibus tortor vestibulum at. Aenean sollicitudin commodo elementum. Nunc dui leo, porttitor nec velit at...",
        authorId: 1,
        authorImage: "https://www.vodvilapp.com/static/uploads/2_profile.jpg",
        authorName: "Nurullah Kılıç",
        authorUserName: "nurullah",
        timeAgo: "bir ay önce",
      },
    ],
  },
  {
    id: 2,
    name: "Helin Biçen",
    username: "helin",
    email: "Sincere@april.biz",
    profileImage: "https://media-exp1.licdn.com/dms/image/D4E03AQHq7REAXQA2Ig/profile-displayphoto-shrink_800_800/0/1647930077312?e=1661990400&v=beta&t=ywaG_yAHNALUxTA9Ammbcx-46ccXWOj5vh8e1ZF_Ukk",
    bannerImage: "https://media-exp1.licdn.com/dms/image/C4E16AQEkyLMAb1DHGA/profile-displaybackgroundimage-shrink_350_1400/0/1644930182067?e=1661990400&v=beta&t=tnMED_0bWshz3pJC6nI4vV6NsdPVqU5sRPt5NNhotAM",
    comments: [
      {
        id: 1,
        placeTitle: "Galata Kulesi",
        rate: 3,
        content:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas at ex a arcu convallis sagittis. Aliquam eget neque libero. Aenean commodo lorem sed dapibu s egestas. Nullam tristique pellentesque orci, id cursus v elit maximus non. Vivamus cursus mollis gravida. Vestibulum ac orci sed arcu accumsan commodo. Nullam ut gravida dolor. Sed pellentesque tempus nulla, non finibus tortor vestibulum at. Aenean sollicitudin commodo elementum. Nunc dui leo, porttitor nec velit at...",
        authorId: 1,
        authorImage: "https://www.vodvilapp.com/static/uploads/2_profile.jpg",
        authorName: "Nurullah Kılıç",
        authorUserName: "nurullah",
        timeAgo: "bir saat önce",
      },
      {
        id: 2,
        placeTitle: "Gebze Kulesi",
        rate: 2.5,
        content:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas at ex a arcu convallis sagittis. Aliquam eget neque libero. Aenean commodo lorem sed dapibu s egestas. Nullam tristique pellentesque orci, id cursus v elit maximus non. Vivamus cursus mollis gravida. Vestibulum ac orci sed arcu accumsan commodo. Nullam ut gravida dolor. Sed pellentesque tempus nulla, non finibus tortor vestibulum at. Aenean sollicitudin commodo elementum. Nunc dui leo, porttitor nec velit at...",
        authorId: 1,
        authorImage: "https://www.vodvilapp.com/static/uploads/2_profile.jpg",
        authorName: "Nurullah Kılıç",
        authorUserName: "nurullah",
        timeAgo: "bir saat önce",
      },
      {
        id: 3,
        placeTitle: "Esenyurt Köşkü",
        rate: 0.5,
        content:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas at ex a arcu convallis sagittis. Aliquam eget neque libero. Aenean commodo lorem sed dapibu s egestas. Nullam tristique pellentesque orci, id cursus v elit maximus non. Vivamus cursus mollis gravida. Vestibulum ac orci sed arcu accumsan commodo. Nullam ut gravida dolor. Sed pellentesque tempus nulla, non finibus tortor vestibulum at. Aenean sollicitudin commodo elementum. Nunc dui leo, porttitor nec velit at...",
        authorId: 1,
        authorImage: "https://www.vodvilapp.com/static/uploads/2_profile.jpg",
        authorName: "Nurullah Kılıç",
        authorUserName: "nurullah",
        timeAgo: "bir ay önce",
      },
    ],
  },
  {
    id: 3,
    name: "Koray Ercan",
    username: "koray",
    email: "Sincere@april.biz",
    profileImage: "https://media-exp1.licdn.com/dms/image/D4E35AQF_mkwNKf8Hqw/profile-framedphoto-shrink_800_800/0/1642960663447?e=1657018800&v=beta&t=rsdNYWOl2iB8RrShFrTNlIz2SI71Ika_91qqaQjAPHA",
    bannerImage: "https://media-exp1.licdn.com/dms/image/C4E16AQFNtmz5DEIGEw/profile-displaybackgroundimage-shrink_350_1400/0/1644884625899?e=1661990400&v=beta&t=EveGsRODh96eCjSSzLqaVKjePpNq46QuitXDT4mwR_s",
    comments: [
      {
        id: 1,
        placeTitle: "Galata Kulesi",
        rate: 3,
        content:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas at ex a arcu convallis sagittis. Aliquam eget neque libero. Aenean commodo lorem sed dapibu s egestas. Nullam tristique pellentesque orci, id cursus v elit maximus non. Vivamus cursus mollis gravida. Vestibulum ac orci sed arcu accumsan commodo. Nullam ut gravida dolor. Sed pellentesque tempus nulla, non finibus tortor vestibulum at. Aenean sollicitudin commodo elementum. Nunc dui leo, porttitor nec velit at...",
        authorId: 1,
        authorImage: "https://www.vodvilapp.com/static/uploads/2_profile.jpg",
        authorName: "Nurullah Kılıç",
        authorUserName: "nurullah",
        timeAgo: "bir saat önce",
      },
      {
        id: 2,
        placeTitle: "Gebze Kulesi",
        rate: 2.5,
        content:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas at ex a arcu convallis sagittis. Aliquam eget neque libero. Aenean commodo lorem sed dapibu s egestas. Nullam tristique pellentesque orci, id cursus v elit maximus non. Vivamus cursus mollis gravida. Vestibulum ac orci sed arcu accumsan commodo. Nullam ut gravida dolor. Sed pellentesque tempus nulla, non finibus tortor vestibulum at. Aenean sollicitudin commodo elementum. Nunc dui leo, porttitor nec velit at...",
        authorId: 1,
        authorImage: "https://www.vodvilapp.com/static/uploads/2_profile.jpg",
        authorName: "Nurullah Kılıç",
        authorUserName: "nurullah",
        timeAgo: "bir saat önce",
      },
      {
        id: 3,
        placeTitle: "Esenyurt Köşkü",
        rate: 0.5,
        content:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas at ex a arcu convallis sagittis. Aliquam eget neque libero. Aenean commodo lorem sed dapibu s egestas. Nullam tristique pellentesque orci, id cursus v elit maximus non. Vivamus cursus mollis gravida. Vestibulum ac orci sed arcu accumsan commodo. Nullam ut gravida dolor. Sed pellentesque tempus nulla, non finibus tortor vestibulum at. Aenean sollicitudin commodo elementum. Nunc dui leo, porttitor nec velit at...",
        authorId: 1,
        authorImage: "https://www.vodvilapp.com/static/uploads/2_profile.jpg",
        authorName: "Nurullah Kılıç",
        authorUserName: "nurullah",
        timeAgo: "bir ay önce",
      },
    ],
  },
];

export const defaultUser = {
  id: 1,
  name: "Nurullah Kılıç",
  username: "nurullah",
  email: "Sincere@april.biz",
  profileImage: "https://www.vodvilapp.com/static/uploads/2_profile.jpg",
  bannerImage: "https://www.vodvilapp.com/static/uploads/7_banner.jpg",
  comments: [
    {
      id: 1,
      placeTitle: "Galata Kulesi",
      rate: 3,
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas at ex a arcu convallis sagittis. Aliquam eget neque libero. Aenean commodo lorem sed dapibu s egestas. Nullam tristique pellentesque orci, id cursus v elit maximus non. Vivamus cursus mollis gravida. Vestibulum ac orci sed arcu accumsan commodo. Nullam ut gravida dolor. Sed pellentesque tempus nulla, non finibus tortor vestibulum at. Aenean sollicitudin commodo elementum. Nunc dui leo, porttitor nec velit at...",
      authorId: 1,
      authorImage: "https://www.vodvilapp.com/static/uploads/2_profile.jpg",
      authorName: "Nurullah Kılıç",
      authorUserName: "nurullah",
      timeAgo: "bir saat önce",
    },
    {
      id: 2,
      placeTitle: "Gebze Kulesi",
      rate: 2.5,
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas at ex a arcu convallis sagittis. Aliquam eget neque libero. Aenean commodo lorem sed dapibu s egestas. Nullam tristique pellentesque orci, id cursus v elit maximus non. Vivamus cursus mollis gravida. Vestibulum ac orci sed arcu accumsan commodo. Nullam ut gravida dolor. Sed pellentesque tempus nulla, non finibus tortor vestibulum at. Aenean sollicitudin commodo elementum. Nunc dui leo, porttitor nec velit at...",
      authorId: 1,
      authorImage: "https://www.vodvilapp.com/static/uploads/2_profile.jpg",
      authorName: "Nurullah Kılıç",
      authorUserName: "nurullah",
      timeAgo: "bir saat önce",
    },
    {
      id: 3,
      placeTitle: "Esenyurt Köşkü",
      rate: 0.5,
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas at ex a arcu convallis sagittis. Aliquam eget neque libero. Aenean commodo lorem sed dapibu s egestas. Nullam tristique pellentesque orci, id cursus v elit maximus non. Vivamus cursus mollis gravida. Vestibulum ac orci sed arcu accumsan commodo. Nullam ut gravida dolor. Sed pellentesque tempus nulla, non finibus tortor vestibulum at. Aenean sollicitudin commodo elementum. Nunc dui leo, porttitor nec velit at...",
      authorId: 1,
      authorImage: "https://www.vodvilapp.com/static/uploads/2_profile.jpg",
      authorName: "Nurullah Kılıç",
      authorUserName: "nurullah",
      timeAgo: "bir ay önce",
    },
  ],
};
