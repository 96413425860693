import "./index.css";

import TurkeyMap from "./assets/turkey-map.png";
import TurkeyVideo from "./assets/video.mp4"

function Hero() {
  return (
    <div className="hero">
      <div className="hero-content">
        {/* <img src={TurkeyMap} alt=" " className="hero-background" /> */}
        <video autoPlay  muted className="hero-background">
          <source src={TurkeyVideo} type="video/mp4" />
        </video>
        <div className="hero-text">
          <h1>
            DISCOVER <span className="yellow">W</span>ITH US
          </h1>
        </div>
      </div>
    </div>
  );
}

export default Hero;
