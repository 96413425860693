
import { FaWalking, FaBus, FaCar } from "react-icons/fa";



const transportations = [
    {
        id: 1,
        type: "Özel araç",
        icon: <FaCar />,
      },
      {
        id: 2,
        type: "Toplu Taşıma",
        icon: <FaBus />,
      },
      {
        id: 3,
        type: "Yaya",
        icon: <FaWalking />,
      },
]

export default transportations;
