import { GiSuspensionBridge, GiPineTree, GiPartyPopper } from "react-icons/gi";
import { FaPaintBrush, FaMosque } from "react-icons/fa";

const categories = [
  {
    id: 1,
    type: "Eğlence",
    icon: <GiPartyPopper />,
  },
  {
    id: 2,
    type: "Sanat",
    icon: <FaPaintBrush />,
  },
  {
    id: 3,
    type: "Kültür ve Din",
    icon: <FaMosque />,
  },
  {
    id: 4,
    type: "Dış Mekanlar",
    icon: <GiPineTree />,
  },
  {
    id: 5,
    type: "Simgesel Yapılar",
    icon: <GiSuspensionBridge />,
  },
];

export default categories;
