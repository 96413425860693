import ListDetailPage from "../ListPagesSchema";

import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import { PlaceServices } from "../../services";

const VisitedPlaces = () => {
  const { type, username } = useParams();

  const { isLoading, isError, data, error } = useQuery(
    ["going", username],
    async () => {
      return await PlaceServices.getPlaces(type, username)
        .then((data) => data)
        .catch((err) => err);
    }
  );

  if (isLoading) {
    return <h1>Loading...</h1>;
  }
  if (type === "fav") {
    return (
      <ListDetailPage
        listName="Favoriler"
        listUniquePlaceholder="Favorileri buradan arayabilirsin"
        places={data ? data[0]?.get_fav_places : []}
      />
    );
  } else if (type === "visited") {
    return (
      <ListDetailPage
        listName="Gidilenler"
        listUniquePlaceholder="Gidilenleri buradan arayabilirsin"
        places={data ? data[0]?.get_visited_places : []}
      />
    );
  } else if (type === "go") {
    return (
      <ListDetailPage
        listName="Gitmek İstenen"
        listUniquePlaceholder="Gitmek istenen yerleri buradan arayabilirsin."
        places={data ? data[0]?.get_go_places : []}
      />
    );
  }
};

export default VisitedPlaces;
