import { useFormik } from "formik";
//import loginSchema from "../../validations";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate , Link} from "react-router-dom";

import styles from "./index.module.css";

import Input from "../../shared/Input";
import { Button } from "../../shared/Button";

import { AuthServices } from "../../services";
import { useAuth } from "../../context/AuthContext";

import LeftBottom from "../../assets/left-bottom.svg";
import RightTop from "../../assets/right-top.svg";

function RegisterContent() {

  const navigate = useNavigate();

  const { setToken , setUser } = useAuth();

  const { handleSubmit, handleChange, handleBlur, values, errors, touched } =
    useFormik({
      initialValues: {
        name: "",
        surname: "",
        email: "",
        username: "",
        password: "",
        passwordConfirm: "",
        country: "",
      },
      //validationSchema: loginSchema,
      onSubmit: (values) => {
        const userInfos = {
          "name":values.name,
          "surname":values.surname,
          "email":values.email,
          "user_name":values.username,
          "password":values.password,
          "password_confirmation":values.passwordConfirm
        }

        AuthServices.registerNewUser(userInfos)
        .then(message=>{
          navigate(`/profile/${values.username}`)
          console.log(message)
          setUser(message?.user);
          setToken(message?.token);
        })
        .catch(err=>{
          console.error(err);
          toast.error(err?.message);
        })
        console.log(values)
      },
    });

  return (
    <div className={styles.container}>
      <img
        src={LeftBottom}
        alt="Left Bottom"
        className={styles.LeftBottom}
        draggable={false}
      />
      <img
        src={RightTop}
        alt="Right Top"
        className={styles.RightTop}
        draggable={false}
      />
      <div className={styles.content}>
        <div className={styles.header}>
          <h2>Kayıt Ol</h2>
          <h4>
            Bizimle beraber Türkiye’nin güzelliklerini keşfetmek için kayıt ol.
          </h4>
        </div>
        
        <div className={styles.form}>
          <form onSubmit={handleSubmit}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "9px",
                marginBottom: "14px",
              }}
            >
              <Input
                isError={touched.name && errors.name ? true : false}
                errorText={errors.name}
                labelText="Ad"
                placeholder="Ad"
                name="name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
              />
              
              <Input
                isError={touched.surname && errors.surname ? true : false}
                errorText={errors.surname}
                labelText="Soyad"
                placeholder="Soyad"
                name="surname"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.surname}
              />
            </div>

            <Input
              isError={touched.email && errors.email ? true : false}
              errorText={errors.email}
              labelText="E-mail"
              placeholder="Mail adresinizi giriniz"
              name="email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              style={{ marginBottom: "14px" }}
            />
            <Input
              isError={touched.username && errors.username ? true : false}
              errorText={errors.username}
              labelText="Kullanıcı adı"
              placeholder="Kullanıcı adı giriniz"
              name="username"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.username}
              style={{ marginBottom: "14px" }}
            />
            <Input
              isError={touched.password && errors.password ? true : false}
              errorText={errors.password}
              labelText="Parola"
              placeholder="Parola giriniz"
              type="password"
              name="password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              autoComplete="on"

              style={{ marginBottom: "14px" }}
            />
            <Input
              isError={
                touched.passwordConfirm && errors.passwordConfirm ? true : false
              }
              errorText={errors.passwordConfirm}
              labelText="Parola Doğrula"
              placeholder="Parolanızı doğrulayın"
              type="password"
              name="passwordConfirm"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.passwordConfirm}
              autoComplete="on"
              style={{ marginBottom: "14px" }}
            />
            {/* <Input
              isError={touched.country && errors.country ? true : false}
              errorText={errors.country}
              labelText="Ülke"
              placeholder="Ülke şeçiniz"
              type="text"
              name="country"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.country}
              autoComplete="on"
              style={{ marginBottom: "14px" }}
            /> */}
            <Button
              text="Kaydol"
              type="submit"
              theme="success"
              style={{ marginBottom: "19px" }}
            />
          </form>
        </div>
        <h5 className={styles.bottomText}>
          Hesabın var mı? <span ><Link to="/login" className={styles.bottomLink}>Giriş yap.</Link></span>
        </h5>
      </div>
      
    </div>
  );
}

export default RegisterContent;
