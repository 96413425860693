import styles from "./index.module.css";
import "./styles.css";

import { FaEdit, FaTrashAlt, FaEllipsisH } from "react-icons/fa";

import Rate from "../../shared/StarRating";

import { Link } from "react-router-dom";
import { useState } from "react";
import { useAuth } from "../../context/AuthContext";

import toast from "react-hot-toast";

import dayjs from "dayjs";
import localeTr from "dayjs/locale/tr";

import relativeTime from "dayjs/plugin/relativeTime";

import { CommentServices } from "../../services";
import UpdatePopUp from "../../shared/UpdatePopUp";

dayjs.extend(relativeTime);
dayjs.locale(localeTr);

function ReviewContent({
  userId,
  user:author,
  userImage,
  comments,
  commentId,
  placeId,
  placeName,
  content,
  rate,
  timeAgo,
  borderShow,
}) {
  const { user } = useAuth();
  const [openModal, setModal] = useState(false);

  const deleteComment = () => {
    CommentServices.deleteCommentById(commentId)
      .then((data) => {
        console.log(data)
        toast.success("Yorum başarıyla silindi.")
        
      })
      .catch((err) => console.log(err));
  };

  const updateComment = () => {
    setModal(true);
  };

  const [dropdown, setDropdown] = useState(false);

  return (
    <>
      {openModal && (
        <UpdatePopUp
          commentId={commentId}
          openModal={openModal}
          setModal={setModal}
          place_name={placeName}
          place_id={placeId}
          oldRate={rate}
          oldContent={content}
        />
      )}
      <div className={styles.content}>
        <div
          className={
            styles.reviewContent + " " + (!borderShow ? styles.borderNone : "")
          }
        >
          <div className={styles.textContent}>
            {placeName && (
              <div className={styles.titleContent}>
                <Link to={`/place/${placeId}`}>
                  <h3>{placeName ? placeName : "Galata Kulesi"}</h3>
                </Link>
                
                {(userId && userId === user?.id )? (
                  <div className="dropdown-review">
                    <div
                      className="dropbtn menu-button"
                      onClick={() => {
                        setDropdown(!dropdown);
                      }}
                    >
                      <FaEllipsisH />
                    </div>
                    <div
                      id="myDropdown"
                      className={`dropdown-content ${dropdown ? "show" : ""}`}
                    >
                      <div className="sub" onClick={updateComment}>
                        <FaEdit />
                        <h1>Yorumu Düzenle</h1>
                      </div>
                      <div className="sub" onClick={deleteComment}>
                        <FaTrashAlt />
                        <h1>Yorumu Sil</h1>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            )}

            <div className={styles.detailContent}>
              <p>{content}</p>
            </div>
          </div>

          <div className={styles.bottomContent}>
            <div className={styles.profileContent}>
              <img
                 src={userImage}
                alt="Profileimg"
              />
              <h3> <Link to={`/profile/${author?.user_name}`}>{author?.name + " " + author?.surname}</Link> </h3>
              <h2> · {dayjs(timeAgo).fromNow()} </h2>
            </div>
            <div className={styles.rate}>
              <Rate rating={rate ? rate : 0} starRatedColor="#fdd10c" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ReviewContent;
