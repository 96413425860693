import styles from "./index.module.css";


const ProfileSkeleton = () => {

  return (
    <div className={styles.profileContainer}>
      <div className={styles.profileContent}>
        <div className={styles.bannerSection}>
          <div
            className={styles.bannerImage}></div>

          <div className={styles.userInfoSection}>
            <span></span>
            <div className={styles.userInfo}>
              <div className={styles.userTextInfo}>
                <h4></h4>
                <h6></h6>
              </div>
              <span></span>
            </div>
          </div>
        </div>
        <div className={styles.profileDetailContainer}>
          <div className={styles.leftSide}>
            <div className={styles.ProfileList}></div>
            <div className={styles.ProfileList}></div>
            <div className={styles.ProfileList}></div>
          </div>
          <div className={styles.rightSide}>
            <div className={styles.ReviewContent}>
              <div></div>
              <div>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </div>
              <div>
                <span></span>
                <span></span>
              </div>
            </div>
            <div className={styles.ReviewContent}>
              <div></div>
              <div>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </div>
              <div>
                <span></span>
                <span></span>
              </div>
            </div>
            <div className={styles.ReviewContent}>
              <div></div>
              <div>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </div>
              <div>
                <span></span>
                <span></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileSkeleton;
